// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfintegrationwithaitoolforfinalizationandenhancement from "../../blocks/cfintegrationwithaitoolforfinalizationandenhancement/src/Cfintegrationwithaitoolforfinalizationandenhancement";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cfhiringofthecontentcreator from "../../blocks/cfhiringofthecontentcreator/src/Cfhiringofthecontentcreator";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfintegrationaiguidededitingforvideos2 from "../../blocks/cfintegrationaiguidededitingforvideos2/src/Cfintegrationaiguidededitingforvideos2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Translation from "../../blocks/translation/src/Translation";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Cfintegrationwithmidjourneyordalle2forphoto from "../../blocks/cfintegrationwithmidjourneyordalle2forphoto/src/Cfintegrationwithmidjourneyordalle2forphoto";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import Videos from "../../blocks/videos/src/Videos";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Trending from "../../blocks/trending/src/Trending";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import Cffilecompressionforvideos from "../../blocks/cffilecompressionforvideos/src/Cffilecompressionforvideos";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import VideoTrimmer from "../../blocks/videotrimmer/src/VideoTrimmer";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Sorting from "../../blocks/sorting/src/Sorting";
import Cfaibasedvideoandaudiogeneration from "../../blocks/cfaibasedvideoandaudiogeneration/src/Cfaibasedvideoandaudiogeneration";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Reservations from "../../blocks/reservations/src/Reservations";
import Cfintegrationwithnuclia from "../../blocks/cfintegrationwithnuclia/src/Cfintegrationwithnuclia";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Multitieredpricing from "../../blocks/multitieredpricing/src/Multitieredpricing.web";
import Login from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import MultitieredProduct from "../../blocks/multitieredpricing/src/MultitieredProduct.web";
import MultitieredCart from "../../blocks/multitieredpricing/src/MultitieredCart.web";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";





const routeMap = {
Multitieredpricing:{
 component:Multitieredpricing,
path:"/Multitieredpricing"},
MultitieredProduct:{
  component:MultitieredProduct,
 path:"/MultitieredProduct"},
 MultitieredCart:{
  component:MultitieredCart,
 path:"/MultitieredCart"},
Splitpayments2:{
 component:Splitpayments2,
path:"/Splitpayments2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},


Reservations:{
 component:Reservations,
path:"/Reservations"},
Filecompression:{
 component:Filecompression,
path:"/Filecompression"},
Share:{
 component:Share,
path:"/Share"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Cfhiringofthecontentcreator:{
 component:Cfhiringofthecontentcreator,
path:"/Cfhiringofthecontentcreator"},
Cffilecompressionforvideos:{
 component:Cffilecompressionforvideos,
path:"/Cffilecompressionforvideos"},
Cfintegrationwithaiforpreviewandrefinement2:{
 component:Cfintegrationwithaiforpreviewandrefinement2,
path:"/Cfintegrationwithaiforpreviewandrefinement2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Translation:{
 component:Translation,
path:"/Translation"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Videos:{
 component:Videos,
path:"/Videos"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Multitieredpricing:{
 component:Multitieredpricing,
path:"/Multitieredpricing"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Splitpayments2:{
 component:Splitpayments2,
path:"/Splitpayments2"},
Cfintegrationwithaitoolforfinalizationandenhancement:{
 component:Cfintegrationwithaitoolforfinalizationandenhancement,
path:"/Cfintegrationwithaitoolforfinalizationandenhancement"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cfhiringofthecontentcreator:{
 component:Cfhiringofthecontentcreator,
path:"/Cfhiringofthecontentcreator"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfintegrationaiguidededitingforvideos2:{
 component:Cfintegrationaiguidededitingforvideos2,
path:"/Cfintegrationaiguidededitingforvideos2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Translation:{
 component:Translation,
path:"/Translation"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Cfintegrationwithmidjourneyordalle2forphoto:{
 component:Cfintegrationwithmidjourneyordalle2forphoto,
path:"/Cfintegrationwithmidjourneyordalle2forphoto"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
Videos:{
 component:Videos,
path:"/Videos"},
Splitpayments2:{
 component:Splitpayments2,
path:"/Splitpayments2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Trending:{
 component:Trending,
path:"/Trending"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Multitieredpricing:{
 component:Multitieredpricing,
path:"/Multitieredpricing"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Cffilecompressionforvideos:{
 component:Cffilecompressionforvideos,
path:"/Cffilecompressionforvideos"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
VideoTrimmer:{
 component:VideoTrimmer,
path:"/VideoTrimmer"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Cfaibasedvideoandaudiogeneration:{
 component:Cfaibasedvideoandaudiogeneration,
path:"/Cfaibasedvideoandaudiogeneration"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Cfintegrationwithnuclia:{
 component:Cfintegrationwithnuclia,
path:"/Cfintegrationwithnuclia"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Share:{
 component:Share,
path:"/Share"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Cfintegrationwithaiforpreviewandrefinement2:{
 component:Cfintegrationwithaiforpreviewandrefinement2,
path:"/Cfintegrationwithaiforpreviewandrefinement2"},
Cfintegrationwithmidjourneyforvideos:{
 component:Cfintegrationwithmidjourneyforvideos,
path:"/Cfintegrationwithmidjourneyforvideos"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
component:Login,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

import {
  getStorageData,
} from "framework/src/Utilities";

export interface ApiRequestReggData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
  token?: string;
}

export interface Option {
  id: number,
  attributes: {
    description?: string;
    price: string;
    product_id: number,
    productName?: string,
    quality_range: string,
    multitieredpricing_id?: string
  }
}

export interface ProductDetail {
  id: string
  type: string
  attributes: Attributes
  isSelect?: boolean
}

interface Attributes {
  id: number
  product_id: number
  name: string
  quality_range: string
  price: string
  description: string
  format_type: string
  product_attributes: ProductAttributes
}

interface ProductAttributes {
  name: string
  price: string
  description: string
  product_type: string
  image: Image
}

export interface Image {
  url: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productDetail: ProductDetail[];
  selected_Price: string | undefined;
  productDescriptionToken: string;
  selectedPriceType: string;
  content: string;
  allProductsMultitiered: [];
  productName: string,
  productDescription: string,
  productImage: "",
  productId: number,
  multitieredpricingId: number,
  quantity: number,
  price: string,
  userType: string,
  resolutionType: string,
  multiPriceId: number,
  allResolutionType: [];
  allUserType: [];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultitieredpricingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCartApiCallId: string = "";
  getProductApiCallId: string = "";
  allProductsMultitieredApi: string = "";
  updateProductApiCallId: string = "";
  addToCartApi: string = "";
  updatePriceApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      // Customizable Area Start
      productDetail: [],
      selected_Price: "",
      productDescriptionToken: "",
      selectedPriceType: "",
      content: "",
      allProductsMultitiered: [],
      productName: "",
      productDescription: "",
      productImage: "",
      productId: 0,
      multitieredpricingId: 0,
      quantity: 1,
      price: "",
      multiPriceId: 0,
      allResolutionType: [],
      allUserType: [],
      resolutionType: "",
      userType: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const product_Descriptions = message.getData(getName(MessageEnum.navigationProductDescriptionMessage));
      this.setState({ productId: product_Descriptions.item, productDescriptionToken: product_Descriptions.token })
      this.getProductApiCall(product_Descriptions.item);
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId === this.allProductsMultitieredApi) {
        this.setState({
          allUserType: responseJson.user_type.map((item:string, index: number) => ({key: index, name: item})),
          allResolutionType: responseJson.resolution_type.map((item:string, index: number) => ({key: index, name: item})),
          allProductsMultitiered: responseJson.pricing_tiers,
          productName: responseJson.pricing_tiers[0].attributes.product_attributes.name,
          productDescription: responseJson.pricing_tiers[0].attributes.product_attributes.description,
          productImage: responseJson.pricing_tiers[0].attributes.product_attributes.image.url,
          price: responseJson.pricing_tiers[0].attributes.price,
          selectedPriceType: responseJson.pricing_tiers[0].attributes.quality_range,
          productId: responseJson.pricing_tiers[0].attributes.product_id,
          multitieredpricingId: responseJson.pricing_tiers[0].id,
        });
      } else if (apiRequestCallId === this.createCartApiCallId) {
        if (responseJson.data) {
          this.navigateAddToCart()
        }
      } else if (apiRequestCallId === this.getProductApiCallId) {
        this.setState({ productDetail: responseJson.pricing_tiers, allResolutionType: responseJson.resolution_type, allUserType: responseJson.user_type, selected_Price: responseJson.pricing_tiers[0].attributes.product_attributes.price })

      } else if (apiRequestCallId === this.updateProductApiCallId) {
        this.setState({ selected_Price: responseJson.price })
      } else if(apiRequestCallId === this.updatePriceApi) {
        this.setState({price: responseJson.price})
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgLogin = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgLogin.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgLogin);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handlePress = (selectedId: number) => {
    const updated_Price_List = this.state.productDetail.map((price: ProductDetail) => {
      return { ...price, isSelect: price.attributes.id === selectedId };
    });
    let selectedPrice: string = "";
    if (updated_Price_List.length > 0) {
      let foundPrice = updated_Price_List.find(
        (price: ProductDetail | string) => typeof price !== 'string' && price.attributes.id === selectedId
      );

      if (foundPrice && typeof foundPrice !== 'string') {
        selectedPrice = foundPrice.attributes.price;
      }
    } else { selectedPrice = "0" }
    this.setState({ productDetail: updated_Price_List, selected_Price: selectedPrice, multiPriceId: selectedId }, () => this.updateProductApiCall("", ""))
  };

  addToCartScreenNavigate = () => {
    const existing_Cart_Items = this.state.productDetail;
    const existingItem = existing_Cart_Items.find((items: ProductDetail) => items.isSelect);
    this.addToCartApiCall(existingItem?.attributes.id)
  };
  addToCartScreenNavigateProps = {
    onPress: this.addToCartScreenNavigate
  }
  requestCartData = () => {
    const request_cart_message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(request_cart_message);
  }

  addToCartApiCall = async (multitieredPricingId: number | undefined) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.productDescriptionToken,
    };
    const httpBody = {
      cart: {
        product_id: this.state.productId,
        multitieredpricing_id: multitieredPricingId,
        quantity: 1,
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAndGetCartListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateProductApiCall = async (userType: string, resolutionType: string) => {
    if (this.state.multiPriceId == 0) {
      this.showAlert('Alert', "Please select product type");
      return
    }

    const endpoint = configJSON.updateProductApiEndPoint + '/' + this.state.multiPriceId;
    const queryParams = [];

    if (userType) {
      queryParams.push('user_type=' + userType);
      this.setState({ userType: userType })
    }

    if (resolutionType) {
      queryParams.push('resolution_type=' + resolutionType);
      this.setState({ resolutionType: resolutionType })
    }

    if(!userType && this.state.userType) {
      queryParams.push('user_type=' + this.state.userType);
    }

    if(!resolutionType && this.state.resolutionType) {
      queryParams.push('resolution_type=' + this.state.resolutionType);
    }

    const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

    const finalEndpoint = endpoint + queryString;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.productDescriptionToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProductApiCall = (productID: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.productDescriptionToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductPricingApiEndPoint + productID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  navigateAddToCart = () => {
    const navigate_to_message: Message = new Message(
      getName(MessageEnum.navigationAddToCartMessage)
    );
    navigate_to_message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigate_to_message.addData(getName(MessageEnum.AddToCartDetailMessage), this.state.productDescriptionToken);
    this.send(navigate_to_message);
  }

  async componentDidMount() {
    if (this.isPlatformWeb()) {
      this.getAllProductsMultitiered();
    } else {
      this.requestCartData();
    }
  }

  apiCall = async (data: ApiRequestReggData) => {
    const { contentType, method, endPoint, body, type } = data;
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage)); request.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type != "formData" ? request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)) : request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(request.id, request);
    return request.messageId;
  };

  getAllProductsMultitiered = async () => {
    const productId = await getStorageData("productId");
    this.allProductsMultitieredApi = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `${configJSON.getProductPricingApiEndPoint}${productId}`,
    });
  };

  capitalizeText(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  handleAddToCart = async () => {
    const productDetails = {
      cart: {
        product_id: this.state.productId,
        multitieredpricing_id: this.state.multitieredpricingId,
        quantity: this.state.quantity
      }
    }
    this.addToCartApi = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "bx_block_multitieredpricing/carts",
      body: productDetails
    });
    this.toNavigate("MultitieredCart");
  };

  toNavigate = (screen: string, params?: Record<string, string>) => {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      screen
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    if (params) {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.navigationProductDescriptionMessage), params);
      navigateMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    }
    this.send(navigateMessage)
  }

  handleCart = () => {
    this.toNavigate("MultitieredCart")
  }

  updatePrice = async () => {
    const body = {
      resolution_type: this.state.resolutionType,
      user_type: this.state.userType
    }
    this.updatePriceApi = await this.apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `${configJSON.updateProductApiEndPoint}/${this.state.multitieredpricingId}`,
      body: body
    });
  };

  handleResolution = (value: string) => {
    this.setState({ resolutionType: value }, this.updatePrice);
  };

  handleUserType = (value: string) => {
    this.setState({ userType: value }, this.updatePrice);
  };

  handleClick = (event: string, option: Option) => {
    this.setState({
      selectedPriceType: event,
      productId: option.attributes.product_id,
      multitieredpricingId: option.id,
      price: option.attributes.price,
    }, () => this.updatePrice());
  };

  handleHomeBack = () => {
    this.toNavigate("MultitieredProduct")
  };
  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "multitieredpricing";
exports.labelBodyText = "multitieredpricing Body";
exports.loginApiEndPoint = "logins/login";
exports.btnExampleTitle = "CLICK ME";
exports.productListApiEndPoint = "bx_block_multitieredpricing/products";
exports.createAndGetCartListApiEndPoint = "bx_block_multitieredpricing/carts";
exports.removeCartItemApiEndPoint = "bx_block_multitieredpricing/carts/remove_cart_item?cart_item_id=";
exports.updateCartListApiEndPoint = "bx_block_multitieredpricing/carts/update_product?quantity=";
exports.updateProductApiEndPoint = "bx_block_multitieredpricing/multitieredpricings";
exports.getProductPricingApiEndPoint="bx_block_multitieredpricing/multitieredpricings/show_mtp/"
exports.backButtonText ="BACK";
exports.homeButtonText ="HOME"
exports.multiteredPricingHeading = "Multi-tiered Pricing";
exports.addToCartHeading="All CartItems";
exports.allProductHeading="All Products"
// Customizable Area End